import { useContext, useState } from "react";
import ItemCount from "./ItemCount";
import styled from "styled-components";
import Maicero3010a420 from "../Surcos/Maicero3010a420";
import Maicero3010 from "../Surcos/Maicero3010";
import MaiceroMDD200 from "../Surcos/MaiceroMDD200";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Girasolero1040 from "../Surcos/Girasolero1040";
import { Button, Container } from "react-bootstrap";
import { SalmekContext } from "../../Context/Context";
import "./ItemDetail.css"

const TitleDiv = styled.div`
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const ModalDiv = styled.div`
  .title {
    color: #4a00e0;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .table {
    overflow: auto;

    table {
      border-collapse: collapse;
      font-size: 14px;
      width: 474px;

      th {
        font-weight: 500;
      }

      td,
      th {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        text-align: center;
        padding: 10px;
      }

      tr {
        th:first-child,
        td:first-child {
          border-left: 1px solid #ddd;
        }

        th:last-child,
        td:last-child {
          border-right: 1px solid #ddd;
        }
      }
    }
  }
`;

const Table = styled.div`
  margin-top: 20px;
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

const Div = styled.div`
  margin-top: 10px;
`;

const Img = styled.img`
  margin: auto;
  max-height: 450px;
  object-fit: scale-down;
`;
const ImgT = styled.img`
  margin: auto;
  width: 95%;
  margin-left: 2em;
  object-fit: fill;
  @media screen and (max-width: 1000px) {
    margin-left: 1em;
  }
`;

const B = styled.b`
  color: red;
`;

const A = styled.a`
  text-decoration: none;
  color: white;
`;

const ModalContainer = styled.div`
  display: ${(props) => (props.isopen ? "block" : "none")};
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
`;

const ModalContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .expanded-image {
    max-width: 95%;
    max-height: 95%;
  }

  .navigation-buttons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    color: white;
  }

  .navigation-button {
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    font-size: 18px;
    transition: background-color 0.3s;
  }

  .navigation-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const VideoSection = styled.div`
  margin-top: 30px;
  display: flex;
  height: auto;
  justify-content: space-around;
`;

export default function ItemDetail({ product, isInCart, onAddToCart }) {
  const {
    codigo,
    category,
    detail,
    imgUrls,
    imgUrlPdf,
    name,
    price,
    stock,
    pdf,
    extra1,
    extra2,
    extra3,
    extra4,
    extra5,
    extra6,
    videoUrl,
    videoUrl2
  } = product;
  const [modalOpen, setModalOpen] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState("");
  const { theme } = useContext(SalmekContext);

  const containerStyles = {
    backgroundColor: theme === 'dark' ? '#343a40' : '#fff',
    color: theme === 'dark' ? '#fff' : '#343a40',
  };

  const openModal = (image, index) => {
    setCurrentImage(image.url);
    setCurrentIndex(index);
    setModalOpen(image);
  };

  const closeModal = () => {
    setModalOpen(null);
  };

  const renderSlides = imgUrls.map((url, index) => (
    <Div key={index} onClick={() => openModal({ url }, index)}>
      <Img src={url} alt={`image${index}`} onClick={() => closeModal()} />
    </Div>
  ));

  
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imgUrls.length - 1 : prevIndex - 1
    );
    setCurrentImage(imgUrls[currentIndex]);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imgUrls.length - 1 ? 0 : prevIndex + 1
    );
    setCurrentImage(imgUrls[currentIndex]);
  };

  return (
    <>
      {product.name && (
        <div className="itemdetailcontainer" style={containerStyles}>
          <div className="row item-detail">
            <div className="col-sm-12 col-md-6">
              <Carousel
                showArrows={true}
                autoPlay={true}
                infiniteLoop={true}
                selectedItem={currentIndex}
                onChange={setCurrentIndex}
                className="carousel-container"
              >
                {renderSlides}
              </Carousel>
            </div>

            <div className="col-sm-12 col-md-6 text-center my-4">
              <h4>{name}</h4>
              <p>CODIGO:{codigo}</p>
              <p>{detail}</p>
              <ModalDiv>
                {category !== "Cosecha" || codigo === "TOLVA" ? (
                  ""
                ) : (
                  <TitleDiv>
                    <b>Distancias y Surcos</b>
                  </TitleDiv>
                )}
                <Table>
                  {codigo === "51541" ? (
                    <Maicero3010 />
                  ) : codigo === "51540" ? (
                    <Maicero3010a420 />
                  ) : codigo === "GIRASOL" ? (
                    <Girasolero1040 />
                  ) : codigo === "63132" ? (
                    <MaiceroMDD200 />
                  ) : (
                    ""
                  )}
                </Table>
              </ModalDiv>
              <h5>
                {price === "0" ? (
                  <b>CONSULTE POR PRECIO</b>
                ) : price === "SIN STOCK" ? (
                  <B>NO DISPONIBLE</B>
                ) : (
                  <b> PRECIO: USD {price.toLocaleString()}</b>
                )}
              </h5>

              <p style={containerStyles} >Stock: {stock}</p>
              {isInCart ? (
                <p
                  className="alert alert-secondary mx-auto"
                  style={{ width: "fit-content" }}
                >
                  El producto ya se encuentra en el Carrito
                </p>
              ) : (
                <ItemCount stock={stock} onAddToCart={onAddToCart}/>
              )}
              {category === "Repuestos" ? (
                ""
              ) : (
                <Button className="btn btn-success border-dark">
                  <A href={pdf} target="_blank" rel="noreferrer">
                    Descargar un Folleto
                  </A>
                </Button>
              )}
            </div>
            <br />
            <br />
            <h5>Descripción:</h5>
            <br />
            <br />
            <Container>
              <ul>
                <li>{extra1}</li>
                {extra2 === "" ? (
                  ""
                ) : (
                  <li>{extra2}</li>
                )}
                {extra3 === "" ? (
                  ""
                ) : (
                  <li>{extra3}</li>
                )}
                {extra4 === "" ? (
                  ""
                ) : (
                  <li>{extra4}</li>
                )}
                {extra5 === "" ? (
                  ""
                ) : (
                  <li>{extra5}</li>
                )}
                {extra6 === "" ? (
                  ""
                ) : (
                  <li>{extra6}</li>
                )}
              </ul>
            </Container>

            <div>
              {category === "Repuestos" ? (
                ""
              ) : (
                <>
                <br/>
                  <h5>Especificaciones técnicas: </h5>
                  <br />
                  <ImgT src={imgUrlPdf} alt={imgUrlPdf} />
                  <br />
                  <br />
                </>
              )}
            </div>
            <div className="videocontainer">
              {videoUrl === "" ? (
                ""
              ) : videoUrl2 === "" ? (
                <>
                  <h5>Videos relacionados: </h5>
                  <VideoSection>
                    <div className="col-sm-12 col-md-12">
                      <div className="row">
                        <div className="col-sm-12 col-md-6 p-3">
                          <iframe
                            // width="100%"
                            className="Iframe"                           
                            src={videoUrl}
                            title="YouTube video player"
                            FrameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </VideoSection>
                </>
              ) : (
                <>
                <br/>
                <h5>Videos relacionados: </h5>
                  <VideoSection>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="row">
                        <div className="col-sm-12 col-md-6 p-3">
                          <iframe
                            className="Iframe"
                            src={videoUrl}
                            title="YouTube video player"
                            FrameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                        <div className="col-sm-12 col-md-6 p-3" >
                          <iframe
                            className="Iframe2"
                            src={videoUrl2}
                            title="YouTube video player"
                            FrameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </VideoSection>
                </>
              )}
            </div>
          </div>
     
        </div>
      )}
      <ModalContainer isopen={modalOpen}>
        <ModalContent>
          <img src={currentImage} alt="Expanded" className="expanded-image" onClick={closeModal} />
          <div className="navigation-buttons">
            <button
              className="navigation-button"
              onClick={handlePrevious}
            >
              &lt; Anterior
            </button>
            <button className="navigation-button" onClick={handleNext}>
              Siguiente &gt;
            </button>
          </div>
        </ModalContent>
      </ModalContainer>
    </>
  );
}