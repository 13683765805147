import { useState, useEffect, useContext } from 'react';
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import { getOfertas } from '../../firebase/firebase';
import { SalmekContext } from '../../Context/Context';

const Section = styled.section`
  padding-top: 40px;
  `;

const Title = styled.h1`
  margin-left: auto;
  margin-right: auto;
    text-align: center;
    font-family: 'Baloo 2', sans-serif;
    font-weight: 800;
    font-size: 3rem;
    @media only screen and (max-width: 800px){
      font-size: 2rem;
      }
  `;

const MachineContainer = styled.div`
  display: flex;
  width: 90%;
  margin: auto;
  flex-wrap: wrap;
  justify-content: center;
  background-color: transparent;
  `;

const MachineCard = styled.div`
  position: relative;
  width: 24%;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  &&:hover {
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    }
    @media only screen and (max-width: 800px){
      font-size:medium;
      width: 100%;
      }
`;

const MachineTitle = styled.h1`
  margin-top: 10px;
  margin-bottom: 5px;
  font-size:large;
  @media only screen and (max-width: 1500px){
    }
  @media only screen and (max-width: 800px){
  font-size:medium;
  }
`;

const MachineDescription = styled.p`
  margin-top: 10px;
  font-size:large;
  @media only screen and (max-width: 1500px){
    }
  @media only screen and (max-width: 800px){
  font-size:medium;
  }
`;

const MachinePrice = styled.p`
  font-weight: 900;
  font-family: Roboto, Sans Serif;
  margin-top: 30px;
  margin-left: 20px;
  margin-bottom: 30px;
  font-size:large;
  @media only screen and (max-width: 1500px){
    }
  @media only screen and (max-width: 800px){
  font-size:medium;
  }
`;

const ModalContainer = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
`;

const ModalContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
      font-family: 'Baloo 2', sans-serif !important;
    font-weight: 800 !important;
    font-size: 3rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FichaTecnicaButton = styled(Link)`
  position: absolute;
  bottom: 15px;
  right: 15px;
  text-decoration: none;
  &:hover {
        box-shadow: 0 0 10px rgba(245, 245, 245, 0.8);
        background-color: whitesmoke;
      }
`;

export default function HomeContainer() {
  const [expandedImage, setExpandedImage] = useState(null);
  const [products, setProducts] = useState([]);
  const { theme } = useContext(SalmekContext);

  const containerStyles = {
    backgroundColor: theme === 'dark' ? '#343a40' : '#fff',
    color: theme === 'dark' ? '#fff' : '#343a40',
  };

  useEffect(() => {
    const fetchProductos = async () => {
      const productsData = await getOfertas();
      setProducts(productsData);
    };

    fetchProductos();
  }, []);

  const openExpandedImage = (image) => {
    setExpandedImage(image);
  };

  const closeExpandedImage = () => {
    setExpandedImage(null);
  };
  return (
    <Section id='ofertas' style={containerStyles}>
      <Title>
        <b>Ofertas Especiales</b>
      </Title><hr></hr>

      <MachineContainer>
        {products.map((machine) => (
          <MachineCard key={machine.id} >
            <Carousel
              showArrows={true}
              autoPlay={true}
              showThumbs={false}
              infiniteLoop={true}
            >
              <div onClick={() => openExpandedImage(machine.imgUrl)}>
                <img src={machine.imgUrl} alt={machine.title} />
              </div>
              <div onClick={() => openExpandedImage(machine.imgUrl2)}>
                <img src={machine.imgUrl2} alt={machine.title} />
              </div>
              <div onClick={() => openExpandedImage(machine.imgUrl3)}>
                <img src={machine.imgUrl3} alt={machine.title} />
              </div>
              <div onClick={() => openExpandedImage(machine.imgUrl4)}>
                <img src={machine.imgUrl4} alt={machine.title} />
              </div>
            </Carousel>
            <MachineTitle>{machine.name}</MachineTitle>
            <MachineDescription>{machine.detail}</MachineDescription>
            <MachinePrice>USD {machine.price}</MachinePrice>
            <br/>
            <FichaTecnicaButton to={`/detail/${machine.id}`} className="btn btn-outline-primary" style={containerStyles}>Ver más</FichaTecnicaButton>
          </MachineCard>
        ))}

        <br />
      </MachineContainer>


      <ModalContainer isOpen={expandedImage}>
        <ModalContent onClick={closeExpandedImage}>
          <img src={expandedImage} alt="Expanded" className="expanded-image" />
        </ModalContent>
      </ModalContainer>
    </Section>
  );
};


